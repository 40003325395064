import React, { Component } from 'react'
import SwiperCore, { Autoplay, Virtual } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Button from '@/components/Button/index'

import './index.less'
import 'swiper/swiper.less'

SwiperCore.use([Autoplay, Virtual])

class AnalysisPlatForm extends Component {
  private textSwiper: any = null
  private pcSwiper: any = null
  private mobileSwiper: any = null
  state = {
    pageIndex: 0,
    btnLoading: false,
    swiperConfig: {
      autoplay: {
        disableOnInteraction: false
      }
    }
  }

  componentDidMount() {
    this.textSwiper.autoplay.stop()
    this.pcSwiper.autoplay.stop()
    this.mobileSwiper.autoplay.stop()
  }

  private nextPage() {
    this.textSwiper.autoplay.stop()
    this.pcSwiper.autoplay.stop()
  }

  render() {
    let { swiperConfig, btnLoading, pageIndex } = this.state

    return (
      <div className="analysis-platform">
        <div className="nav-bg">
          <Swiper
            className="pc-swiper"
            autoplay={swiperConfig.autoplay}
            spaceBetween={50}
            noSwiping={true}
            slidesPerView={1}
            loop={true}
            noSwipingClass="stop-swiping"
            onAutoplayStart={(swiper) => (this.textSwiper = swiper)}
          >
            <SwiperSlide className="stop-swiping">
              <div className="nb-title">
                <p>门店AI数据雷达</p>
                <p>
                基于“三重归因模型”，洞察各品牌、各门店的销售能力，目标客群与实际客群的一致性洞察，会员转化漏斗，提供数十种消费全流程数据模型
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="stop-swiping">
              <div className="nb-title">
                <p>智能标签系统</p>
                <p>
                  基于进店行为数据，搭建用户标签系统，帮助运营者更好洞察消费者，实现客群定位、分层管理、品牌招调、精准营销
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="stop-swiping">
              <div className="nb-title">
                <p>智能广告分发</p>
                <p>
                  算法分发系统通过顾客行动路线、时间规律，消费偏好等累积数据，自动选择合适的投放区域和时间，动态投放广告内容，为品牌门店实现精准导流
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide className="stop-swiping">
              <div className="nb-title">
                <p>AI导购雷达</p>
                <p>
                智能客户识别营销工具，帮助导购挖掘潜在客户，洞悉历史消费偏好，实现精准商品推荐，实现个性化导购服务
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className="nb-btn-wrapper">
            <Button
              className="nb-btn"
              onClick={() => {
                this.textSwiper.slideNext()
                this.pcSwiper.slideNext()
                this.setState({
                  pageIndex: pageIndex === 3 ? 0 : pageIndex + 1
                })
              }}
              text={
                pageIndex === 3
                  ? '重新开始'
                  : pageIndex === 0
                  ? '点击开始'
                  : '下一页'
              }
              loading={btnLoading}
            />
          </div>
          <div className="card-container">
            <div className="pc-card">
              <img alt="" src={require('@/assets/ap-pc-card.png')} />
              <Swiper
                className="pc-swiper"
                autoplay={swiperConfig.autoplay}
                spaceBetween={50}
                slidesPerView={1}
                loop={true}
                noSwipingClass="stop-swiping"
                onAutoplayStart={(swiper) => (this.pcSwiper = swiper)}
              >
                <SwiperSlide className=" stop-swiping swiper-slide-img slide-img-1">
                  <img alt="" src={require('@/assets/ap-swiper-pc-1.png')} />
                </SwiperSlide>
                <SwiperSlide className="stop-swiping swiper-slide-img slide-img-2">
                  <img alt="" src={require('@/assets/ap-swiper-pc-2.png')} />
                </SwiperSlide>
                <SwiperSlide className="stop-swiping swiper-slide-img slide-img-3">
                  <img alt="" src={require('@/assets/ap-swiper-pc-3.png')} />
                </SwiperSlide>
                <SwiperSlide className="stop-swiping swiper-slide-img slide-img-3">
                  <img alt="" src={require('@/assets/ap-swiper-pc-4.png')} />
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="mobile-card">
              <img alt="" src={require('@/assets/ap-mobile-card.png')} />
              <Swiper
                className="mobile-swiper"
                autoplay={swiperConfig.autoplay}
                spaceBetween={50}
                slidesPerView={1}
                loop={true}
                direction={'vertical'}
                onAutoplayStart={(swiper) => (this.mobileSwiper = swiper)}
              >
                <SwiperSlide className="swiper-slide-img slide-img-1">
                  Slide 1
                </SwiperSlide>
                <SwiperSlide className="swiper-slide-img slide-img-2">
                  Slide 2
                </SwiperSlide>
                <SwiperSlide className="swiper-slide-img slide-img-3">
                  Slide 3
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>

        {/* <section className="ap-model">
          <p className="title">
            <span>多维度客流数据模型</span>
          </p>

          <ul className="ap-model-content">
            <li>
              <img alt="" src={require("@/assets/ap-liuliang.png")} />
              <p>流量统计</p>
              <p>
                门店客流高低峰
                <br /> 营销活动有效性 <br /> 客流量趋势变化
                <br /> 门店客流量漏斗
                <br /> 品牌/区域流量排行
              </p>
            </li>
            <li>
              <img alt="" src={require("@/assets/ap-huaxiang.png")} />
              <p>消费者画像</p>
              <p>
                构建门店顾客画像
                <br /> 识别单一顾客到店频次 <br />
                识别会员、回头客、新客
                <br /> 分析顾客逛店行为
                <br /> 采集顾客偏好标签数据
              </p>
            </li>
            <li>
              <img alt="" src={require("@/assets/ap-shangping.png")} />
              <p>商品组合</p>
              <p>
                全场景流量热力图 <br />
                楼层、品牌转化分析
                <br /> 品牌门店客量重叠图 <br />
                首店、人群分发链图 <br />
                最佳关联品牌组合
              </p>
            </li>
            <li>
              <img alt="" src={require("@/assets/ap-shuju.png")} />
              <p>数据运营</p>
              <p>
                全局数据、实时数据
                <br /> 相关异常运营数据预警
                <br /> 同期，往期数据诊断 <br />
                机器学习智能决策建议
              </p>
            </li>
          </ul>
        </section> */}
      </div>
    )
  }
}

export default AnalysisPlatForm
