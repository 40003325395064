import Home from "@/views/home";
import AboutUs from "@/views/about-us";
import Partner from "@/views/partner";
import TianYan from "@/views/tianyan";
import VipFace from "@/views/vipface";
import ZhiKe from "@/views/zhike";
import AnalysisPlatForm from "@/views/analysis-platform";
import SolutionMall from "@/views/solution-mall";
import SolutionBrand from "@/views/solution-brand";
import AlgExperience from "@/views/alg-experience";
const routes = [
  {
    path: "/",
    exact: true,
    component: Home,
    meta: {
      title: "首页",
    },
  },
  {
    path: "/product/vipface",
    exact: true,
    component: VipFace,
    meta: {
      title: "VipFace",
    },
  },
  {
    path: "/product/tianyan",
    exact: true,
    component: TianYan,
    meta: {
      title: "tianyan",
    },
  },
  {
    path: "/solution-mall",
    exact: true,
    component: SolutionMall,
    meta: {
      title: "商场",
    },
  },
  {
    path: "/solution-brand",
    exact: true,
    component: SolutionBrand,
    meta: {
      title: "品牌",
    },
  },
  {
    path: "/partner",
    exact: true,
    component: Partner,
    meta: {
      title: "合作伙伴",
    },
  },
  {
    path: "/about",
    exact: true,
    component: AboutUs,
    meta: {
      title: "关于我们",
    },
  },
  {
    path: "/product/zhike",
    exact: true,
    component: ZhiKe,
    meta: {
      title: "关于我们",
    },
  },
  {
    path: "/analysis-platform",
    exact: true,
    component: AnalysisPlatForm,
    meta: {
      title: "分析平台",
      headerHidden: true
    },
  },
  {
    path: "/alg-experience",
    exact: true,
    component: AlgExperience,
    meta: {
      title: "算法体验你",
    },
  },
];

export default routes;
