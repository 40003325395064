import { AxiosResponse, AxiosError } from "axios";

// 响应请求拦截
const responseSuccessFunc = (response: AxiosResponse) => {
  let { data } = response;

  if (data.code === -1 || data.code === -4) {
    const message = data.message || data.msg;
    alert(message)
    return Promise.reject(message || 'Error');
  }
  return response.data.data;
};

// 响应失败拦截
const responseFailFunc = (error: AxiosError) => {
  return Promise.reject(error);
};

export { responseSuccessFunc, responseFailFunc };
