import React, { Component } from "react";

interface IProps {
  angle?: number; // 圆弧大小
  className?: string; // 类名
  id?: string;
}

class Partner extends Component<IProps> {
  static getDerviedStateFromProps(nextProps: IProps) {
    return {};
  }

  componentDidMount() {
    
    const {angle, id} = this.props;
    const c = document.getElementById(id!) as HTMLCanvasElement;
    const ctx = c!.getContext("2d")!;

    let mW = (c.width = 176);
    let mH = (c.height = 176);
    let lineWidth = 5;
    const r = mW / 2; // 中间位置
    const cR = r - 2; // 园半径
    const startAngle = -((1 / 2) * Math.PI); // 开始角度
    const endAngle = (startAngle + 2 * Math.PI) * (angle || 1); // 结束角度
    const xAngle = 1 * (Math.PI / 180); // 偏移角度
    let tmpAngle = startAngle; // 临时角度变量

    // 渲染函数
    const render = function () {
      if (tmpAngle >= endAngle) {
        return;
      } else if (tmpAngle + xAngle > endAngle) {
        tmpAngle = endAngle;
      } else {
        tmpAngle += xAngle;
      }
      ctx.clearRect(0, 0, mW, mH);

      // 画圈
      ctx.beginPath();
      ctx.lineWidth = lineWidth;
      ctx.strokeStyle = "#fff";
      ctx.arc(r, r, cR, startAngle, tmpAngle);
      ctx.stroke();
      ctx.closePath();

      requestAnimationFrame(render);
    };
    render();
  }

  render() {
    const {className, id} = this.props;
    return <canvas id={id} className = {className} />;
  }
}

export default Partner;
