
import axios from 'axios';
import {getCookie} from "@/util/util";
import { requestSuccessFunc, requestFailFunc, responseSuccessFunc, responseFailFunc } from './interceptors';

const AXIOS_DEFAULT_CONFIG = {
  baseURL: "https://surface-api.yuekedata.shop",
  timeout: 20000,
  maxContentLength: 2000,
  withCredentials: false,
  "Content-Type": "application/x-www-form-urlencoded",
  headers: {
    req_str: getCookie('req_str'),
    post: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  },
};

const axiosInstance = axios.create(AXIOS_DEFAULT_CONFIG);

// 注入请求拦截
axiosInstance.interceptors.request.use(requestSuccessFunc, requestFailFunc);

// 注入失败拦截
axiosInstance.interceptors.response.use(responseSuccessFunc, responseFailFunc);

export default axiosInstance;