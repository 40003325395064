import React from 'react'
import { Route, Switch} from 'react-router-dom';

const renderRoutes = (routes:any,  switchProps = {}) => routes ?(
  <Switch {...switchProps}>
    {
      routes.map((route:any,i:number) => (
        <Route 
          key = {route.path || i}
          path = {route.path}
          exact={route.exact}
          strict = {route.strict}
          render = {(props) => {
            return <route.component {...props} route = {route}/>
          }}
        >
        </Route>
      ))
    }
  </Switch>
) : null;

export default renderRoutes;