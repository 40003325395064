import React, { Component } from "react";
import Swiper from "swiper";

import { fadeback } from "@/request/api";
import "./index.less";
class About extends Component {
  state = {
    telephone: "",
    content: "",
    title: "",
  };

  componentDidMount() {
    this.initSwiper();
  }

  private initSwiper() {
    new Swiper(".swiper-container", {
      watchSlidesProgress: true,
      slidesPerView: "auto",
      centeredSlides: true,
      loop: true,
      loopedSlides: 5,
      autoplay: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        //clickable :true,
      },
      on: {
        progress: function (swiper: any, progress: any) {
          const slides = swiper.slides;
          for (let i = 0; i < slides.length; i++) {
            var slide = slides.eq(i);
            var slideProgress = slides[i].progress;
            let modify = 1;
            if (Math.abs(slideProgress) > 1) {
              modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
            }
            let translate = slideProgress * modify * 260 + "px";
            let scale = 1 - Math.abs(slideProgress) / 5;
            let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
            slide.transform(
              "translateX(" + translate + ") scale(" + scale + ")"
            );
            slide.css("zIndex", zIndex);
            slide.css("opacity", 1);
            if (Math.abs(slideProgress) > 3) {
              slide.css("opacity", 0);
            }
          }
        },
        setTransition: function (swiper: any, transition: any) {
          const slides = swiper.slides;
          for (var i = 0; i < slides.length; i++) {
            var slide = slides.eq(i);
            slide.transition(transition);
          }
        },
      },
    });
  }

  private async submit() {
    const { title, telephone } = this.state;
    var isPhone = /^1(3|4|5|6|7|8|9)\d{9}$/; //手机号码
    var isMob = /^0\d{2,3}-\d{7,8}$/; // 座机格式
    var isMob400 = /^400-?[016789]\d{2}-?\d{4}$/; // 400电话
    if (!title) {
      alert("请填写公司名称");
      return;
    }
    if (title.length <= 2) {
      alert("公司名称长度大于2");
      return;
    }
    if (
      !isPhone.test(telephone) &&
      !isMob.test(telephone) &&
      !isMob400.test(telephone)
    ) {
      alert("请填写正确的联系方式");
      return;
    }

    await fadeback({
      title,
      telephone,
      content: this.state.content,
    });
    alert("您的信息我们已收到");
    this.setState({
      telephone: "",
      content: "",
      title: "",
    });
  }

  render() {
    const { telephone, content, title } = this.state;
    return (
      <div className="about-us">
        <div className="nav-bg">
          <div className="nav-text">
            <p className="nav-first-title">商雀科技-商业数智化技术提供商</p>
            <p className="nav-second-title">
              商雀科技核心团队来自ReadSense、SenseTime等知名人工智能企业，致力于将AI技术与商业结合，为商业空间提供全方位的数智化解决方案；核心产品：视加客户行为分析平台、先知智能广告营销平台、天衍商业算法平台、知客AI数据终端等。
            </p>
          </div>
        </div>

        <section className="as-intro">
          <div className="as-intro-content">
            <div className="as-intro-left">
              <img src={require("@/assets/about-shangque.png")} alt="" />
            </div>

            <div className="as-intro-right">
              <p className="title">价值观</p>
              <p>成就客户，极致创新，正直敬业，务实进取</p>

              <p className="title">使命</p>
              <p>汇聚数据力量，构建更加美好的商业、生活环境</p>

              <p className="title">愿景</p>
              <p>成为行业领先的商业科技公司</p>
            </div>
          </div>
        </section>

        <section className="as-function">
          <div className="as-function-container">
            <div className="prefix-line-white prefix-line">我们的优势</div>
            <div className="as-function-block">
              <div className="funciton-block funciton-block1">
                <img src={require("@/assets/as-function-1.png")} alt="" />
                <p className="fb-title1">技术水平</p>
                <p className="fb-title2">
                  自主研发
                  <br />
                  专利产品
                  <br />
                  行业领先
                </p>
              </div>
              <div className="funciton-block funciton-block2">
                <img src={require("@/assets/as-function-2.png")} alt="" />
                <p className="fb-title1">解决方案</p>
                <p className="fb-title2">
                  深耕数据价值
                  <br />
                  聚焦行业痛点
                  <br />
                  全场景产品覆盖
                </p>
              </div>
              <div className="funciton-block funciton-block3">
                <img src={require("@/assets/as-function-3.png")} alt="" />
                <p className="fb-title1">项目实施</p>
                <p className="fb-title2">
                  现场勘察
                  <br />
                  现场交付
                  <br />
                  全国运维
                </p>
              </div>
              <div className="funciton-block funciton-block4">
                <img src={require("@/assets/as-function-4.png")} alt="" />
                <p className="fb-title1">售后服务</p>
                <p className="fb-title2">
                  7X24h售后
                  <br />
                  需求收集
                  <br />
                  持续迭代
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* 资质证书 */}
        <section className="as-qualification">
          <div className="prefix-line">资质证书</div>
          <div id="certify">
            <div className="swiper-container">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <img alt="" src={require("@/assets/sv-swiper1.png")} />
                </div>
                <div className="swiper-slide">
                  <img alt="" src={require("@/assets/sv-swiper2.png")} />
                </div>
                <div className="swiper-slide">
                  <img alt="" src={require("@/assets/sv-swiper3.png")} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="as-position">
          <div className="as-position-container">
            <div className="prefix-line-white prefix-line">运营中心</div>

            <div className="as-position-block">
              <div className="as-p-cell">深圳公司总部</div>
              <div className="as-p-cell">上海运营中心</div>
              <div className="as-p-cell">成都运营中心</div>
              <div className="as-p-cell">郑州运营中心</div>
            </div>
          </div>
        </section>

        <section className="as-contact">
          <div className="as-contact-container">
            <div className="prefix-line">联系我们</div>
            <div className="contact-us">
              <input
                placeholder="公司名称"
                className="contact-input"
                value={title}
                onChange={(e) => {
                  const value = e.target.value;
                  this.setState({
                    title: value,
                  });
                }}
              />
              <input
                placeholder="联系方式"
                className="contact-input"
                value={telephone}
                onChange={(e) => {
                  const value = e.target.value;
                  this.setState({
                    telephone: value,
                  });
                }}
              />

              <textarea
                placeholder="意向描述"
                className="contact-input contact-input-area"
                value={content}
                onChange={(e) => {
                  const value = e.target.value;
                  this.setState({
                    content: value,
                  });
                }}
              />
            </div>
            <div className="contact-btn" onClick={() => this.submit()}>
              <span>提交</span>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default About;
