import { AxiosRequestConfig, AxiosError } from "axios";

// 请求成功拦截
export function requestSuccessFunc(requestObj: AxiosRequestConfig) {
  if (requestObj.data.hasOwnProperty("isFormRequest")) {
    const newParams = new FormData();
    for (let i in requestObj.data) {
      if (i !== "isFormRequest") {
        if (requestObj.data[i].length) {  
            newParams.append(i, requestObj.data[i]);
        } else {
          newParams.append(i, requestObj.data[i]);
        }
  
      }
    }
    requestObj.data = newParams;
  }

  return requestObj;
}

// 请求失败拦截
export function requestFailFunc(requestError: AxiosError) {
  return Promise.reject(requestError);
}

export default {
  requestSuccessFunc,
  requestFailFunc,
};
