import React, { Component } from 'react'
import FaceDetection from './components/FaceDetection'
import FaceContrast from './components/FaceContrast'
import FaceAnalysis from './components/FaceAnalysis'
import FaceRetrieval from './components/FaceRetrieval'
import './index.less'

class AlgorithmicExperience extends Component {
  state = {
    imgUrl: '',
    componentName: 'detection',
    interval: 0,
    currTabValue: 0,
    tabTitles: {
      detection:
        '对给定的图像进行人脸检测，识别并定位出人脸的准确置，可支持多张人脸的同框检测',
      analysis: '对图像中的人脸进行属性分析，识别其性别和年龄、眼镜等',
      contrast:
        '对给定的两张图像进行比对分析，判断两者的相似程度，可兼容口罩、低头、侧脸等复杂情况',
      retrieval:
        '模拟人脸的检索过程，算法会自动检索出人脸库中最相似的图片，并给出识别相似度'
    }
  }
  inputFile: any = null

  // 切换组件
  private switchComponent(name: string, val: number) {
    this.setState({
      componentName: name,
      currTabValue: val,
      interval: val - this.state.currTabValue
    })
  }

  private getImageSrc(img: string) {
    this.setState({
      imgUrl: img
    })
  }

  renderComponentType() {
    const { componentName, interval } = this.state
    const className = interval > 0 ? 'left-slider' : 'right-slider'
    switch (componentName) {
      case 'detection':
        return <FaceDetection className={className} />
      case 'analysis':
        return <FaceAnalysis className={className} />
      case 'contrast':
        return <FaceContrast className={className} />
      default:
        return <FaceRetrieval className={className} />
    }
  }

  render() {
    const { componentName } = this.state
    return (
      <div className="alg-exper">
        <div className="nav-bg">
          <img src = {require("@/assets/experience-center.png")} alt = "" />
          <div className="tp-txt">
            <p>
              天衍算法 · 技术体验中心
              <br />
              Tianyan Algorithm Platform
            </p>
            <p>
              口罩检测 &nbsp;人脸检测 &nbsp;表情识别 &nbsp;属性识别
              &nbsp;配饰识别 &nbsp;服装识别{' '}&nbsp;
              {/* <Link to="/product/vipface">更多详情 {'>'}</Link> */}
            </p>
          </div>
        </div>
        <div className="nav-menu">
          <div
            className={
              componentName === 'detection' ? 'nav-item active' : 'nav-item'
            }
            onClick={() => this.switchComponent('detection', 1)}
          >
            <div className="nav-item-link">人脸检测</div>
          </div>
          <div
            className={
              componentName === 'analysis' ? 'nav-item active' : 'nav-item'
            }
            onClick={() => this.switchComponent('analysis', 2)}
          >
            <div className="nav-item-link">属性分析</div>
          </div>
          <div
            className={
              componentName === 'contrast' ? 'nav-item active' : 'nav-item'
            }
            onClick={() => this.switchComponent('contrast', 3)}
          >
            <div className="nav-item-link">人脸比对</div>
          </div>

          <div
            className={
              componentName === 'retrieval' ? 'nav-item active' : 'nav-item'
            }
            onClick={() => this.switchComponent('retrieval', 4)}
          >
            <div className="nav-item-link">人脸检索</div>
          </div>
        </div>
        {this.renderComponentType()}
      </div>
    )
  }
}

export default AlgorithmicExperience
