import React, { Component } from 'react'
import CircleProcess from '@/components/CircleProcess/index'
import CountTo from 'react-count-to'
import './index.less'

class Partner extends Component {
  private node: any

  componentDidMount() {
    this.node.scrollIntoView()
  }

  render() {
    return (
      <div className="partner" ref={(node) => (this.node = node)}>
        <div className="nav-bg">
          <section className="about-tip">
            <div className="about-tip-li ">
              <div>
                <p>
                  <CountTo to={500} speed={3000} delay={0} />
                  万+
                </p>
                <p>单日识别Face ID</p>
                <CircleProcess
                  className="circle-process"
                  id="circle-process1"
                  angle={0.7}
                />
              </div>
            </div>
            <div className="about-tip-li">
              <div>
                <p>
                  <CountTo to={2000} speed={3000} delay={0} />+
                </p>
                <p>服务门店</p>
                <CircleProcess
                  className="circle-process"
                  id="circle-process2"
                  angle={0.4}
                />
              </div>
            </div>
            <div className="about-tip-li">
              <div>
                <p>
                  <CountTo to={50} speed={3000} delay={0} />+
                </p>
                <p>入驻城市</p>
                <CircleProcess
                  className="circle-process"
                  id="circle-process3"
                  angle={0.6}
                />
              </div>
            </div>
          </section>
        </div>

        <div className="title animate__animated animate__bounce">合作伙伴</div>
        <section className="section-grey">
          <div className="section-li">
            <div className="left animate__animated animate__fadeInLeft">
              <p className="l-title">岁宝百货</p>
              <p className="l-content">
                岁宝百货（股票代码：00312.HK）成立于1996年，是一家综合性连锁商业企业。2019年，岁宝百货与阿里旗下盒马科技联合推出全球首个“线上线下统一管理”的数字化购物广场--“盒马里·岁宝”项目。
              </p>
            </div>
            <div className="right animate__animated animate__fadeInRight block">
              <img alt="" src={require('@/assets/suibao.png')} />
              <div className="hoverEffe">岁宝百货</div>
            </div>
          </div>
        </section>
        <section>
          <div className="section-li-2">
            <div className="left animate__animated animate__fadeInLeft">
              <img alt="" src={require('@/assets/zuoyuehui.png')} />
              <div className="hoverEffe">卓悦汇</div>
            </div>
            <div className="right animate__animated animate__fadeInRight">
              <p className="r-title">卓悦汇</p>
              <p className="r-content">
                深圳卓悦汇购物中心项目为卓越集团在商业地产领域推出的核心品牌和旗舰级产品，作为40万平米商务综合体卓越城的有机组成部分，深圳卓悦汇坐拥10万平米体量，入驻品牌超200家。
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="section-grey">
            <div className="section-li">
              <div className="left animate__animated animate__fadeInLeft">
                <p className="l-title">欧尚汽车</p>
                <p className="l-content">
                  长安欧尚汽车依托长安汽车集团研发资源，拥有核心研发人员3500余人，专家博士150余人，外籍专家70余人。现有终端体验店1500家，2020年将达到1000家。
                </p>
              </div>
              <div className="right animate__animated animate__fadeInRight block">
                <img alt="" src={require('@/assets/qiche.png')} />
                <div className="hoverEffe">欧尚汽车</div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="section-li-2">
            <div className="left animate__animated animate__fadeInLeft block">
              <img alt="" src={require('@/assets/ytyht.png')} />
              <div className="hoverEffe"> 伊藤洋华堂</div>
            </div>
            <div className="right animate__animated animate__fadeInRight ">
              <p className="r-title"> 伊藤洋华堂</p>
              <p className="r-content">
              伊藤洋华堂成立于1996年，是经原对外经济贸易合作部（现商务部）批准成立的中外合资公司。集服装、家居用品、食品为一体，经营商品包括生鲜食品在内达4万余种。
              </p>
            </div>
          </div>
        </section>

        <section className="section-grey">
          <div className="section-li">
            <div className="left animate__animated animate__fadeInLeft">
              <p className="l-title">晶融汇</p>
              <p className="l-content">
              成都晶融汇是铁狮门以深度的全球性经验、专长和资源优势，携手美国SOM、美国ETI、美国KWP等十多个国际顶尖团队，在成都CBD中心打造的多功能城市地标，总建筑面积约17万平米。
              </p>
            </div>
            <div className="right animate__animated animate__fadeInRight block">
              <img alt="" src={require('@/assets/jinronghui.png')} />
              <div className="hoverEffe">晶融汇</div>
            </div>
          </div>
        </section>

        <section>
          <div className="section-li-2">
            <div className="left animate__animated animate__fadeInLeft block">
              <img alt="" src={require('@/assets/xinhua.png')} />
              <div className="hoverEffe">新华书店</div>
            </div>
            <div className="right animate__animated animate__fadeInRight">
              <p className="r-title">新华书店</p>
              <p className="r-content">
              新华书店是中华人民共和国国有图书发行企业，隶属于中共中央宣传部，中国出版集团之下，是国家官方的书店，也是国家刊物宣传与发售处之一。
              </p>
            </div>
          </div>
        </section>
     
    

        <section className="section-grey">
          <div className="section-li">
            <div className="left animate__animated animate__fadeInLeft">
              <p className="l-title">远大未来广场</p>
              <p className="l-content">
                远大未来广场是远大置业有限公司在焦作鼎力开发的高端地产项目中的重要部分，该项目鼎立焦作四区中央核心，尊踞城市价值高地，千亿市政配套，纯熟片区，总投资逾15亿元人民币。
              </p>
            </div>
            <div className="right animate__animated animate__fadeInRight block">
              <img alt="" src={require('@/assets/yuanyang.png')} />
              <div className="hoverEffe">远大未来广场</div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default Partner
