import React, { Component } from "react";
import './index.less'

class TextComponent extends Component {
  render() {
    return (
      <div className="text">
        <div className = "content">
          商雀科技成立于2019年8月，总部位于深圳。核心技术团队来自阅面网络ReadSense、商汤科技SenseTime等知名人工智能企业。
          基于机器视觉的数据采集能力，配合大数据技术和人工智能算法，构建新一代客户行为分析和需求洞察系统。为线下商业的判断与决策提供数字支撑，以期发挥数据的最大价值，赋能线下经济产业链。
        </div>
        <ul className = "content">
          发展历程
          <li>2019年8月，公司成立</li>
          <li>2019年9月，获得“种子轮”投资</li>
          <li>2019年10月，商业AI算法平台“天衍”上线</li>
          <li>2019年11月，客户行为分析平台“视加”上线</li>
          <li>2020年1月，签约岁宝百货，数字化南京等项目</li>
          <li>2020年5月，签约远大时代商业、李宁、新华书店等项目</li>
          <li>2020年8月，签约晶融汇、卓越商业、伊藤洋华堂等项目</li>
        </ul>
        <div className = "content">
          公司业务
          线下传统商业（包括：百货商场、购物中心、品牌门店等）的客流量数据统计方法，以往采用红外线、WiFi等技术，存在数据单一且不够精准等问题。真正的数字化运营，需要更丰富的数据维度，更精准的判断，更智能的价值挖掘。
          商雀科技，采用人脸识别设备，采集真实、精准、多维丰富的客户数据，通过人工智能算法的分析，生成每个到访顾客的用户画像，真正实现线下商业的“千人千面”。
          硬件设备
          <div>
            知客：AI客群分析摄像机 <br />
            嵌入AI芯片，基于深度学习和视觉技术，包含检测、追踪、采集、分析模块。
            功能包括：流量统计（人脸图像采集）；属性识别（年龄、性别、新老客户、会员）；行动轨迹记录，区域热力分析；黑名单预警；兴趣标签采集
          </div>
          <div>
            先知 - 边缘计算AI互动终端
            <br />
            基于客群行为和画像，品牌广告可在特定时间和空间位置的广告分发窗口进行可视化展示，从而引导“潜在”顾客进店。
          </div>
          <div>
            先巡 - AI巡店摄像头
            <br />
            智能督导巡店终端
          </div>
        </div>
        <div className = "content">
          <div>AI算法</div>
          “天衍”商业AI算法平台
          <ul>
            基于深度学习推理引擎、视觉算法、 多模态理解、学习推荐系统，
            聚焦于商业场景算法识别任务，
            兼容第三方人脸摄像机、识别平板等智能AI硬件接入。 算法功能包括：
            <li>口罩算法：疫情期间快速响应，敏捷开发 </li>
            <li>人体算法：支持数十人同框，人脸运动、追踪人脸位置定位 </li>
            <li>人脸、表情算法：颜值打分、心情指数、性别与年龄识别 </li>
            <li>
              {" "}
              配饰、服装识别：眼镜、配饰、背包识别；12种服装颜色、
              9种服装款式识别
            </li>
            <li>
              到访即偏好，跨时间、空间采集顾客各个节点数据。基于对顾客消费信息的采集，进行自动标签分类。
            </li>
            <li>
              如：消费者经常出入星巴克，系统自动标签为商务人士、咖啡爱好者等；如消费者经常出入LV门店，系统自动标签为奢饰品爱好者、消费能力较高等。
            </li>
          </ul>
        </div>
        <div className = "content">
          <div>SaaS平台</div>
          <div>视加：客户行为数据分析平台</div>
          <ul>
            <li>
              针对商场顾客，构建消费者全流程、多方位行为数据。包括性别、年龄属性、购买偏好、消费能力评估。可按同比、环比、历史同期等数十种数据模型。
            </li>
            <li>
              针对入驻商家，基于“三重归因模型”，洞察各品牌、各门店的销售能力，目标客群与实际客群的一致性，会员转化漏斗等。
            </li>
            <li>
              针对商场，绘制消费者画像，消费行为及品牌的关系图，辅助业态配比，为营销方案提供数字化支撑。
            </li>
          </ul>

          <div>部分功能详解</div>
          <div> 客流统计：去重/新增/老客/会员；客流KPI/同比/环比</div>
          <ul>
            <li>趋势分析：客流量变化未来趋势</li>
            <li>活跃分析：不同活跃度用户占比</li>
            <li>首店到访分布：顾客到访商场的首选商家，反映商家的引流能力</li>
            <li>
              客流分发：顾客到访商家之前、之后游逛的商家
              ，反映商家、品牌之间的关联度
            </li>
          </ul>

          <div> 转化分析：客流转化情况</div>
          <ul>
            <li>楼层转化：商场不同楼层转化率</li>
            <li> 业态排行：按业态统计商家热度 </li>
            <li>品牌排行：按品牌统计商家热度</li>
          </ul>

          <div> 客户画像：年龄/性别/基础信息/消费偏好/品牌偏好</div>
          <ul>
            <li>
              标签统计：按顾客行为规则生成标签。如：有车族、奶茶控、工作餐白领…
            </li>
            <li>标签编辑：对既有标签规则进行编辑；自定义新增标签规则</li>
          </ul>
        </div>
        <div className = "content">
          <div>服务案例</div>
          <ul>
            <li>
              <p>岁宝百货</p>
              （股票代码：00312.HK）成立于1996年的综合性连锁商业企业。
              2019年，岁宝与盒马科技联合推出全球首个“线上线下统一管理”的
              数字化购物广场“盒马里·岁宝”。
              商雀现已入驻岁宝购物广场兴宁店、龙岗店、景田店、陆河店等11个商场。
            </li>
            <li>
              <p>伊藤洋华堂</p>
              成立于1996年，是经原对外经济贸易合作部（现商务部）批准成立的中外合资公司。
              集服装、家居用品、食品为一体，经营商品包括生鲜食品在内达4万余种。
              商雀现已进驻伊藤洋华堂春熙店、双楠店、乐山店、眉山店等9家商场。
            </li>
            <li>
              <p>卓悦汇</p>
              卓越集团在商业地产领域推出的核心品牌，
              坐拥10万平米体量，入驻品牌超200家。
              商雀已进驻卓悦汇、卓悦INTOWN等购物中心。
            </li>
            <li>
              <p>晶融汇</p>
              铁狮门携手美国SOM、美国ETI、美国KWP等十多个国际顶尖团队，
              在成都CBD打造的多功能城市地标，总建筑面积约17万平米。
              商雀已在晶融汇商场出入口、楼层、门店布置数据设备。进行精准
              客流分析，辅助客流数字化运营。
            </li>
          </ul>
        </div>
        <div className = "content">方案优势</div>
        <ul>
          <ol>
            {" "}
            高颗粒度：基于Face
            ID收集数据，避免传统（红外线计数）方案的信息污染，如重复统计、谬误统计、维度单一等
          </ol>
          <ol>
            {" "}
            高协同性：通过Face
            ID，积累顾客线下消费行为的全部数据，并接入第三方线上数据进行整合，最终形成顾客的ONE
            ID
          </ol>
          <ol>
            {" "}
            高性价比：不高于原有预算的前提下，提供更多维的数据和更全面的服务
          </ol>
          <ol>
            高整合度：硬件设备、算法、SaaS平台统一开发，避免衔接龃龉，责任推诿或功能重合
          </ol>
          <ol> 高敏捷度：快速响应用户真实场景的具体需求，敏捷开发迭代新功能</ol>
        </ul>
      </div>
    );
  }
}

export default TextComponent;
