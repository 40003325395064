import React, { Component, createRef } from 'react'
import { getFaceDet } from '@/request/api'
import Spin from '@/components/Spin'
import './index.less'

interface IProps {
  imgUrl: any
  hiddenBtn?: boolean
  btnText?: string
  hiddenInfoBox?: boolean
  handFile: (arg: any | undefined) => void
  getFile?: (arg: any) => void
}

class AlgorithmicExperience extends Component<IProps, any> {
  state = {
    url: require('@/assets/upload-icon.png'),
    propUrl: '',
    errorUrl: require('@/assets/face-fail-icon.png'),
    errorInfo: '',
    currImgType: 'default', // default, upload, prop, error
    innerUpdate: true,
    faceArrs: [
      {
        det_bbox: {}
      }
    ] as any,
    attrResults: [{}] as any,
    mutilImgShowIndex: 0,
    imageRate: 0,
    loading: false
  }
  faceImg = createRef<HTMLImageElement>()
  uploadFile = createRef<HTMLInputElement>()

  static getDerivedStateFromProps(nextProps: IProps, preState: any) {
    if (nextProps.imgUrl !== preState.propUrl && nextProps.imgUrl) {
      return {
        loading: true,
        currImgType: 'prop',
        propUrl: nextProps.imgUrl,
        url: nextProps.imgUrl
      }
    }
    return null
  }

  componentDidMount() {
    this.props.handFile(this.uploadFile)
  }

  componentDidUpdate(_: any, preState: any) {
    const { imgUrl } = this.props
    if (
      preState.url !== this.props.imgUrl &&
      this.state.currImgType === 'prop' &&
      imgUrl
    ) {
      if (imgUrl.indexOf('.png') >= 0 || imgUrl.indexOf('.jpg') >= 0) {
        let image = new Image()
        image.src = this.props.imgUrl
        image.setAttribute('crossOrigin', 'Anonymous')
        image.onload = () => {
          const baseImg = this.getBase64Image(image)
          const file = this.convertBase64UrlToBlob(baseImg) as any
          this.analysis(file)
        }
      } else {
        const baseImg = {
          dataURL: imgUrl,
          type: 'image/png'
        }
        const file = this.convertBase64UrlToBlob(baseImg) as any
        this.analysis(file)
      }
    }
  }

  private async onFileChange(event: any) {
    const { getFile } = this.props
    const files = event.target.files
    this.setState({
      loading: true
    })
    if (files && files[0]) {
      getFile && getFile(files[0])
      const reader = new FileReader()
      reader.readAsDataURL(files[0])
      reader.onload = (e: any) => {
        const src = e.target.result
        const isLt1M = files[0].size / 1024 / 1024 < 1
        if (!isLt1M) {
          this.setState({
            currImgType: 'error',
            loading: false,
            errorInfo: '上传图片不能超过 1MB'
          })
        } else {
          this.setState(
            {
              url: src,
              currImgType: 'upload'
            },
            () => {
              this.analysis(files[0])
            }
          )
        }
      }
    }
  }

  private async analysis(file: File) {
    setTimeout(async () => {
      const naturalWidth = this.faceImg.current?.naturalWidth
      let { imageRate } = this.state
      if (naturalWidth! > 0) {
        imageRate = 350 / naturalWidth!
      }
      getFaceDet({
        file
      })
        .then((faceInfo: any) => {
          this.setState({
            faceArrs: faceInfo.cvFaceDetResults,
            attrResults: faceInfo.attrResults,
            imageRate,
            mutilImgShowIndex: 0,
            loading: false
          })
        })
        .catch(() => {
          this.setState({
            currImgType: 'error',
            errorInfo: '未知错误，请确认网络是否正常',
            loading: false
          })
        })
    })
  }

  private getBase64Image(img: any) {
    var canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height
    var ctx: any = canvas.getContext('2d')
    ctx.drawImage(img, 0, 0, img.width, img.height)
    var ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase()
    var dataURL = canvas.toDataURL('image/' + ext)
    return {
      dataURL: dataURL,
      type: 'image/' + ext
    }
  }

  private convertBase64UrlToBlob(base64: any) {
    var urlData = base64.dataURL
    var type = base64.type
    var bytes = window.atob(urlData.split(',')[1]) //去掉url的头，并转换为byte
    //处理异常,将ascii码小于0的转换为大于0
    var ab = new ArrayBuffer(bytes.length)
    var ia = new Uint8Array(ab)
    for (var i = 0; i < bytes.length; i++) {
      ia[i] = bytes.charCodeAt(i)
    }
    return new Blob([ab], { type: type })
  }

  renderUploadImg() {
    const { currImgType, url, errorInfo, errorUrl } = this.state
    if (currImgType === 'default') {
      return (
        <div className = "img-block">
          <img src={url} alt="" ref={this.faceImg} className="img-img-icon" />
          <div className="upload-text">请上传图片</div>
        </div>
      )
    } else if (currImgType === 'error') {
      return (
        <div className = "img-block">
          <img
            src={errorUrl}
            alt=""
            ref={this.faceImg}
            className="img-img-icon"
          />
          <div className="upload-text">{errorInfo}</div>
        </div>
      )
    } else {
      return (
        <div className = "img-block">
          <img src={url} alt="" ref={this.faceImg} className="img-experience" />
        </div>
      )
    }
  }

  renderFaceBox() {
    const {
      faceArrs,
      attrResults,
      imageRate,
      mutilImgShowIndex,
      currImgType
    } = this.state
    const isShowInfo = currImgType === 'upload' || currImgType === 'prop'
    const { hiddenInfoBox } = this.props
    return faceArrs.map((item: any, index: number) => {
      const attrInfo = attrResults[index]
      return (
        <div key={`face-box-${index}`}>
          {isShowInfo && (
            <div
              className="face-item"
              onMouseOver={() =>
                this.setState({
                  mutilImgShowIndex: index
                })
              }
              style={{
                width: item.det_bbox.width * imageRate,
                height: item.det_bbox.height * imageRate,
                left: item.det_bbox.xmin * imageRate,
                top: item.det_bbox.ymin * imageRate
              }}
            >
              <div className="face-line left-top" />
              <div className="face-line right-top" />
              <div className="face-line left-bottom" />
              <div className="face-line right-bottom" />
            </div>
          )}
          {!hiddenInfoBox && isShowInfo && mutilImgShowIndex === index ? (
            <div
              className="face-items-info"
              style={{
                left: item.det_bbox.xmin * imageRate - 90,
                top: item.det_bbox.ymin * imageRate
              }}
            >
              <div className="item-list">
                性别：{attrInfo.sex === 0 ? '女' : '男'}
              </div>
              <div className="item-list">年龄：{attrInfo.age}</div>
              <div className="item-list">
                眼镜：{attrInfo.glasses <= 0.5 ? '无' : '有'}
              </div>
            </div>
          ) : null}
        </div>
      )
    })
  }

  render() {
    const { loading } = this.state
    const { hiddenBtn, btnText } = this.props
    return (
      <div className="face-img">
        <div className="result-img-container">
          <Spin loading={loading}>
            <div
              className="img-result"
              onClick={() => this.uploadFile.current?.click()}
            >
              <div className="img-experience-block">
                {this.renderUploadImg()}
                {!loading && this.renderFaceBox()}
              </div>
            </div>
          </Spin>
          <label
            className="location-img-uplaod"
            htmlFor="upload"
            style={{ display: hiddenBtn ? 'none' : 'inherit' }}
          >
            {btnText || '上传本地照片'}
          </label>
          <input
            type="file"
            ref={this.uploadFile}
            onChange={(e) => this.onFileChange(e)}
            name="upload"
            id="upload"
          />
        </div>
      </div>
    )
  }
}

export default AlgorithmicExperience
