import React, { Component, createRef } from "react";
import Button from "@/components/Button/index";
import { faceCompare } from "@/request/api";
import "../index.less";

class AlgorithmicExperience extends Component<any, any> {
  state = {
    loading: false,
    uploadUrl: require("@/assets/upload-icon.png"),
    leftUrl: "",
    leftFile: null,
    rightUrl: "",
    rightFile: null,
    value: 0,
    timing: "-"
  };
  leftFaceImg = createRef<HTMLInputElement>();
  rightFaceImg = createRef<HTMLInputElement>();

  componentDidUpdate() {}

  private async onFileChange(event: any, type: string) {
    const files = event.target.files;
    if (files && files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (e: any) => {
        const src = e.target.result;
        this.setState({
          [`${type}Url`]: src,
          [`${type}File`]: files[0]
        });
      };
    }
  }

  private async analysis() {
    const { leftFile, rightFile } = this.state;
    if (!leftFile || !rightFile) {
      alert("请先上传左右两侧图片");
      return;
    }
    this.setState({
      loading: true
    });
    const res = await faceCompare({
      file: leftFile,
      file1: rightFile
    }).catch(() => {
      this.setState({
        loading: false
      });
    });
    this.setState({
      value: res,
      timing: (Math.random() * 100).toFixed(2),
      loading: false
    });
  }

  render() {
    const { leftUrl, rightUrl, uploadUrl, value, timing, loading } = this.state;
    return (
      <div className={`face-contrast ${this.props.className}`}>
        <div className="experience-container-info">
          <div className="result-img-container">
            <div
              className="img-result"
              onClick={() => this.leftFaceImg.current?.click()}
            >
              <div className="img-experience-block">
                <img
                  src={leftUrl || uploadUrl}
                  alt=""
                  className={leftUrl ? "img-experience" : "upload-img-icon"}
                />
                {!leftUrl && <div className="upload-text">请上传图片</div>}
              </div>
            </div>
            <div>请将鼠标移至标记的人头像处</div>
            <label className="location-img-uplaod" htmlFor="upload">
              上传本地照片
            </label>
            <input
              type="file"
              ref={this.leftFaceImg}
              onChange={(e) => this.onFileChange(e, "left")}
              name="upload"
              id="upload"
            />
          </div>
          <div className="ml-title">
            <img src={require("@/assets/vs.png")} alt="" />
          </div>
          <div className="result-img-container">
            <div
              className="img-result"
              onClick={() => this.rightFaceImg.current?.click()}
            >
              <div className="img-experience-block">
                <img
                  src={rightUrl || uploadUrl}
                  alt=""
                  className={rightUrl ? "img-experience" : "upload-img-icon"}
                />
                {!rightUrl && <div className = "upload-text">请上传图片</div>}
              </div>
            </div>
            <label className="location-img-uplaod" htmlFor="upload1">
              上传本地照片
            </label>

            <input
              type="file"
              ref={this.rightFaceImg}
              onChange={(e) => this.onFileChange(e, "right")}
              name="upload1"
              id="upload1"
            />
          </div>
        </div>
        <div className="experience-result">
          <Button
            className="img-analysis"
            text="分析"
            onClick={() => this.analysis()}
            loading={loading}
          ></Button>
          <div className="request-info">
            相似度：{ (value * 100).toFixed(2)  + '%' || 0} 运算时长：{timing} ms
          </div>
        </div>
      </div>
    );
  }
}

export default AlgorithmicExperience;
