import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import './index.less'

class VipFace extends Component {
  render() {
    return (
      <div className="product-introduce">
        <div className="nav-bg">
          <div className="nb-container">
            <div className="nb-text-1">天衍算法</div>
            <div className="nb-text-2">
              <p>AI开放平台</p>
              <p>
                为复杂的商业环境量身打造 &nbsp;赋能广大终端硬件 &nbsp;
                支持私有云、本地化部署
              </p>
            </div>
            <div className="nb-btn">
              <div>
                <Link to="/alg-experience">体验算法</Link>
              </div>
              <div>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="http://tianyan.shangquedata.cn"
                >
                  {' '}
                  获取接口文档{'>'}{' '}
                </a>
              </div>
            </div>
          </div>
        </div>

        <section className="zk-se-first ">
          <div>
            <div className="img">
              <img src={require('@/assets/as-function-1.png')} alt="" />
            </div>
            <p>
              聚焦商业空间
              <br />
              兼容主流厂商摄像头
            </p>
            <p>
              强化训练学习
              <br />
              百万级暗光侧脸针对性训练
              <br />
              降低50%服务器部署成本
            </p>
          </div>
          <div>
            <div className="img">
              <img src={require('@/assets/as-function-2.png')} alt="" />
            </div>
            <p>
              快速接入
              <br />
              毫秒级算法结果输出
            </p>
            <p>
              20ms特征提取
              <br />
              100ms算法比对
              <br />
              1s内完成数据传递
            </p>
          </div>
          <div>
            <div className="img">
              <img src={require('@/assets/as-function-3.png')} alt="" />
            </div>
            <p>
              机器视觉理解能力
              <br />
              开启设备的“智慧之眼”
            </p>
            <p>
              表情识别、颜值识别
              <br />
              服装颜色及款式识别、配饰识别
              <br />
              更丰富的算法维度
            </p>
          </div>
        </section>

        <section className="zk-se-second">
          <div className="container">
            <p className="title">核心功能</p>

            <div className="block">
              <div className="block-item">
                <div className="img">
                  <img src={require('@/assets/ty-function-1.png')} alt="" />
                </div>
                <p>口罩识别</p>
                <p>
                  口罩人脸特征提取 <br />
                  头肩特征辅助
                  <br />
                  准确率95%以上
                </p>
              </div>
              <div className="block-item">
                <div className="img">
                  <img src={require('@/assets/ty-function-2.png')} alt="" />
                </div>
                <p>移动监测</p>
                <p>
                  支持数十人同框
                  <br />
                  运动追踪
                  <br />
                  位置定位
                </p>
              </div>
              <div className="block-item">
                <div className="img">
                  <img src={require('@/assets/ty-function-3.png')} alt="" />
                </div>
                <p>表情识别</p>
                <p>
                  颜值打分
                  <br />
                  心情指数
                  <br />
                  趣味互动
                </p>
              </div>
            </div>
            <div className="block">
              <div className="block-item">
                <div className="img">
                  <img src={require('@/assets/ty-function-4.png')} alt="" />
                </div>
                <p>特征属性</p>
                <p>
                  性别识别
                  <br />
                  年龄识别
                  <br />
                  适配各种复杂环境
                </p>
              </div>
              <div className="block-item">
                <div className="img">
                  <img src={require('@/assets/ty-function-5.png')} alt="" />
                </div>
                <p>配饰识别</p>
                <p>
                  配饰识别
                  <br />
                  背包款式识别
                  <br />
                  近视眼镜、墨镜分类识别
                </p>
              </div>
              <div className="block-item">
                <div className="img">
                  <img src={require('@/assets/ty-function-6.png')} alt="" />
                </div>
                <p>服装识别</p>
                <p>
                  支持12种服装颜色识别
                  <br />
                  支持9种服装款式识别
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default VipFace
