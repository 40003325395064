import React, { Component } from 'react'
import './index.less'

class SolutionMall extends Component {
  render() {
    return (
      <div className="solution-mall">
        <div className="nav-bg">
          <section className="sm-challenge">
              <p>从经验判断到数据决策</p>
              <p>助力精细化运营，提升经营效率</p>
          </section>
        </div>

        <section className="sm-value">
          <div className="section-title">
            <div className="title">
              <p>解决方案及价值</p>
              <p>Solution & Value</p>
            </div>
            <div className="desc">
            采集“人”的行为数据，对“场”的业务标签化，连接“货”品消费信息，实现“人货场”的高度匹配
            </div>
          </div>
          <div className="sm-cv-img">
            <img src={require('@/assets/mall-value.png')} alt="" />
          </div>
        </section>

        <section className="sm-tx-img">
          <div className="se-lt-img ">
            <div className="left">
              <img
                className="sm-img-1"
                src={require('@/assets/sm-img1.png')}
                alt=""
              />
            </div>
            <div className="right">
              <p className="title">“到访即偏好”，构建多维标签，建立全景客户画像</p>
              <p className="content">
              基于AI视觉的跨时间、跨空间能力，建立顾客数字ID标签数据。对每一个数字ID叠加线下行为标签，逐渐形成对每一个客户的认知能力并预测其偏好和消费能力。
              </p>
            </div>
          </div>
        </section>
        <section className="sm-tx-img sm-data">
          <div className="se-lt-tx">
            <div className="left">
              <p className="title">优化营销策略，数据驱动运营，提升客流转化</p>
              <p className="content">
              以流量监测、多维度标签系统为基础，融合消费数据，建立起数字化运营及决策模型，帮助商业管理者科学评估运营状况，优化客流动线，优化业态配比。
              </p>
            </div>
            <div className="right">
              <img src={require('@/assets/sm-img2.png')} alt="" />
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default SolutionMall
