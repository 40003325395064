import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import layout from "./layout/index";
import TextComponent from "./views/text";
import { setCookie, getCookie } from "@/util/util";
import { v4 as uuidv4 } from "uuid";

import "./style/index.less";
import "swiper/swiper-bundle.css";

import * as serviceWorker from "./serviceWorker";

if (!getCookie("req_str")) {
  setCookie("req_str", uuidv4());
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/text" component={TextComponent} />
        <Route path="/" component={layout} />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
