import React, { Component } from 'react'
import routes from '@/router/route'
import { Link } from 'react-router-dom'
import renderRoutes from '@/router/index'
import { sendVerifyCode, sendApplyMail } from '@/request/api'
import Button from '@/components/Button'
import './index.less'
import { getQueryVariable } from '../util/util'

class Layout extends Component<any> {
  private node: any

  state = {
    hoverPath: '',
    showQrcode: false,
    telephone: '',
    verifyCode: '',
    showVerifyCode: false,
    submitLoading: false,
    verifyText: '获取验证码'
  }

  componentDidUpdate(nextProps: any) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.node.scrollIntoView()
    }
  }

  private touchMove(path: string) {
    const { hoverPath } = this.state
    hoverPath !== path &&
      this.setState({
        hoverPath: path
      })
  }

  private async doLoop() {
    const { telephone } = this.state
    if (!telephone || !/^1[3456789]\d{9}$/.test(telephone)) {
      alert('请输入正确的手机号')
      return
    }

    const countSecond = () => {
      let seconds = 60
      let verifyText = seconds + 's后获取'

      const countDown = setInterval(() => {
        if (seconds > 0) {
          verifyText = seconds + 's后获取'
          --seconds
        } else {
          verifyText = '获取验证码'
          clearInterval(countDown)
        }
        this.setState({
          verifyText
        })
      }, 1000)
    }
    countSecond()
    await sendVerifyCode({
      phone: telephone
    })
  }

  private async submit() {
    const { telephone, verifyCode } = this.state
    if (!telephone || !/^1[3456789]\d{9}$/.test(telephone)) {
      alert('请输入正确的手机号')
      return
    }
    if (!verifyCode) {
      alert('请输入验证码')
      return
    }
    this.setState({
      submitLoading: true
    })
    await sendApplyMail({
      phone: telephone,
      verify_code: verifyCode
    })
      .then(() => {
        alert('您的申请我们已收到')
        this.setState({
          showVerifyCode: false,
          submitLoading: false
        })
      })
      .catch(() => {
        this.setState({
          submitLoading: false
        })
      })
  }

  private goOuteWeb(path: string) {
    const tempwindow = window.open('_blank')
    tempwindow!.location.href = path
  }

  renderApplyModal() {
    const {
      telephone,
      verifyCode,
      submitLoading,
      showVerifyCode,
      verifyText
    } = this.state
    return (
      <div
        className="company-apply"
        style={{ display: showVerifyCode ? 'block' : 'none' }}
      >
        <div className="apply-warp">
          <div className="title">企业申请</div>
          <div className="desc">
            为了能给你提供更加专业的一对一服务，请填写以下资料。你也可拨打客服热线：400-777-1955
          </div>
          <img
            alt=""
            className="apply-wrap-close"
            src={require('@/assets/closecc.png')}
            onClick={() =>
              this.setState({
                showVerifyCode: false
              })
            }
          />
          <div className="form">
            <div className="input">
              <input
                type="text"
                placeholder="请输入您的手机号"
                className="inputChange"
                name="phone"
                value={telephone}
                maxLength={11}
                onChange={(e) => {
                  let value = e.target.value
                  value = value.replace(/[^\d]/g, '')
                  this.setState({
                    telephone: value
                  })
                }}
              />

              <div className="te pc-card">
                <input
                  type="text"
                  placeholder="请输入手机验证码"
                  className="phone phoneYzm inputChange"
                  name="yzm"
                  maxLength={6}
                  value={verifyCode}
                  onChange={(e) => {
                    let value = e.target.value
                    value = value.replace(/[^\d]/g, '')
                    this.setState({
                      verifyCode: value
                    })
                  }}
                />
                {verifyText === '获取验证码' ? (
                  <span className="btns" onClick={() => this.doLoop()}>
                    {verifyText}
                  </span>
                ) : (
                  <span className="btns btns-disabled">{verifyText}</span>
                )}
              </div>
              <Button
                className="submit"
                onClick={() => this.submit()}
                text="提交申请"
                loading={submitLoading}
              ></Button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const pathname = this.props.location.pathname
    const params = getQueryVariable();
    const hiddenHeader = params.header.indexOf('hide') >= 0 ? true : false
    const { hoverPath, showQrcode } = this.state
    return (
      <div className="app-container" ref={(node) => (this.node = node)}>
        <header
          className={
            hiddenHeader ? 'app-header-visible app-header app-header-hidden' : 'app-header '
          }
        >
          <img className="logo" alt="" src={require('@/assets/logo.png')} />
          <div className="menu-container">
            <div className="menu-item">
              <Link to="/">
                <span
                  className={
                    pathname === '/' ? 'menu-link active' : 'menu-link '
                  }
                >
                  首页
                </span>
              </Link>
            </div>
            <div className="menu-item">
              <Link to="/alg-experience">
                <span
                  className={
                    pathname === '/alg-experience'
                      ? 'menu-link active'
                      : 'menu-link'
                  }
                >
                  算法体验
                </span>
              </Link>
            </div>
            <div
              className="menu-item"
              onMouseOver={() => this.touchMove('product')}
              onMouseOut={() => this.touchMove('')}
            >
              <span
                className={
                  pathname.indexOf('product') >= 0
                    ? 'menu-link active'
                    : 'menu-link '
                }
              >
                产品介绍
              </span>
              <ul
                className={
                  hoverPath === 'product' ? 'menu-list hovered' : 'menu-list'
                }
              >
                <li>
                  <Link to="/product/vipface">
                    <span
                      className={
                        pathname === '/product/vipface'
                          ? 'menu-link menu-link-active'
                          : 'menu-link '
                      }
                    >
                      天衍算法
                    </span>
                  </Link>
                </li>

                <li>
                  <Link to="/analysis-platform">
                    <span
                      className={
                        pathname === '/analysis-platform'
                          ? 'menu-link menu-link-active'
                          : 'menu-link '
                      }
                    >
                      视加平台
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/product/zhike">
                    <span
                      className={
                        pathname === '/product/zhike'
                          ? 'menu-link menu-link-active'
                          : 'menu-link '
                      }
                    >
                      知客AIOT
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
            <div
              className="menu-item"
              onMouseOver={() => this.touchMove('solution')}
              onMouseOut={() => this.touchMove('')}
            >
              <span
                className={
                  pathname.indexOf('solution') >= 0
                    ? 'menu-link active'
                    : 'menu-link '
                }
              >
                解决方案
              </span>
              <ul
                className={
                  hoverPath === 'solution' ? 'menu-list hovered' : 'menu-list'
                }
              >
                <li>
                  <Link to="/solution-mall">
                    <span
                      className={
                        pathname === '/solution-mall'
                          ? 'menu-link menu-link-active'
                          : 'menu-link '
                      }
                    >
                      商业地产
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to="/solution-brand">
                    <span
                      className={
                        pathname === '/solution-brand'
                          ? 'menu-link menu-link-active'
                          : 'menu-link '
                      }
                    >
                      品牌连锁
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
            {/* <div className="menu-item">
              <Link to="/partner">
                <span
                  className={
                    pathname === '/partner' ? 'menu-link active' : 'menu-link '
                  }
                >
                  合作伙伴
                </span>
              </Link>
              <span className="menu-link"></span>
            </div> */}

            <div className="menu-item">
              <Link to="/about">
                <span
                  className={
                    pathname === '/about' ? 'menu-link active' : 'menu-link '
                  }
                >
                  关于我们
                </span>
              </Link>
            </div>
          </div>
          <div className="menu-right">
            <div
              className="menu-apply-btn"
              onClick={() =>
                this.setState({
                  showVerifyCode: true
                })
              }
            >
              申请使用
            </div>
            <div
              className="menu-right-item"
              onClick={() => this.goOuteWeb('http://www.shangquedata.cn/')}
            >
              <span className="menu-link">登录</span>
            </div>
            <div className="menu-right-item menu-right-item-line">
              <img
                className="qrcode"
                onMouseOver={() =>
                  this.setState({
                    showQrcode: true
                  })
                }
                onMouseLeave={() =>
                  this.setState({
                    showQrcode: false
                  })
                }
                alt=""
                src={require('@/assets/qrcode.png')}
              />
              <div
                className="dropbox"
                style={{ display: showQrcode ? 'block' : 'none' }}
              >
                <i className="dropbox-arrow"></i>
                <div className="dropbox-content">
                  <img
                    className="dropbox-img"
                    src={require('@/assets/qrcode-platform.jpg')}
                    alt=""
                  />
                  <p className="dropbox-p">
                    扫码关注
                    <br />
                    视加AI平台
                  </p>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className={hiddenHeader ? 'app-content-hover' : 'app-content'}>
          {renderRoutes(routes)}
        </div>

        <footer className={hiddenHeader ? 'hidden' : 'app-footer'}>
          <div className="footer-content">
            <div className="fc-1">
              <div>
                <p>联系我们</p>
              </div>
              <div className="fc-1-block">
                <div className="fc-text-sm">
                  <img
                    className="f-icon"
                    src={require('@/assets/telephone.png')}
                    alt=""
                  />
                  400-777-1955
                </div>

                {/* <div className="fc-text-sm">
                  <img
                    className="f-icon f-mailbox"
                    src={require('@/assets/mailbox.png')}
                    alt=""
                  />
                  yueke@yuekedata.com{' '}
                </div> */}
              </div>
              <div className="fc-text-sm">
                {' '}
                <img
                  className="f-icon f-location"
                  src={require('@/assets/location.png')}
                  alt=""
                />
                深圳南山高新技术产业园北京航空航天大厦1号楼1502
              </div>
            </div>

            <div className="fc-1">
              <div>
                <p>下载中心</p>
                <div className="fc-text-sm download-center">
                  <a href="https://seeplus-sass.oss-cn-shenzhen.aliyuncs.com/portal/IPsearch.exe">
                    IP搜索工具
                  </a>
                  <a href="https://seeplus-sass.oss-cn-shenzhen.aliyuncs.com/portal/ZKIPC.apk">
                    调试APP
                  </a>
                  <a href="https://seeplus-sass.oss-cn-shenzhen.aliyuncs.com/portal/vlc-3.0.8-win32.exe">
                    视频预览插件
                  </a>
                </div>
                <div className="fc-text-sm download-center">
                  <a
                    rel="noopener noreferrer"
                    href="https://seeplus-sass.oss-cn-shenzhen.aliyuncs.com/%E7%9F%A5%E5%AE%A2%E6%91%84%E5%83%8F%E6%9C%BA%E5%AE%89%E8%A3%85%E6%8C%87%E5%AF%BC%E6%89%8B%E5%86%8C.pdf"
                    target="_blank"
                  >
                    安装指导手册
                  </a>
                </div>
              </div>
            </div>

            <div className="qrcode-block">
              <div className="fc-qrcode">
                <div className="qrcode-public-platform">
                  <img src={require('@/assets/qrcode-platform.jpg')} alt="" />
                  <p>- 微信公众号 -</p>
                </div>
              </div>
              <div className="fc-qrcode">
                <div className="qrcode-miniprogram">
                  <img
                    src="//21302914.s21i.faiusr.com/2/ABUIABACGAAgroHh8AUo0OO4zQUwoAY4oAY!100x100.jpg"
                    alt=""
                  />
                  <p>- 微信小程序 -</p>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright">
            <div className="copyright-info">
              <a
                href="http://www.beian.gov.cn"
                rel="noopener noreferrer"
                target="_blank"
              >
                2020 - 深圳商雀科技有限公司 版权所有 粤ICP备19146493号
              </a>
            </div>
          </div>
        </footer>

        {this.renderApplyModal()}
      </div>
    )
  }
}

export default Layout
