import request from "./index";

/*********** 人脸检测 *************/
export const getFaceDet = (data?: any) =>
  request({
    url: "/seeplusfacediscern/faceapi/face_det",
    method: "post",
    data: {
      isFormRequest: true,
      ...data
    }
  });

export const faceCompare = (data?: any) =>
  request({
    url: "/seeplusfacediscern/faceapi/face_compare",
    method: "post",
    data: {
      isFormRequest: true,
      ...data
    }
  });

/**************反馈 ******************/
export const fadeback = (data?: any) =>
  request({
    url: "/seeplusbusiness/smtp/fadeback",
    method: "post",
    data
  });

// 发送验证码
export const sendVerifyCode = (data?: any) =>
  request({
    url: "/seeplusbusiness/short-message-record/save",
    method: "post",
    data
  });

  // 发送申请邮件
export const sendApplyMail = (data?: any) =>
request({
  url: "/seeplusbusiness/short-message-record/customer/apply",
  method: "post",
  data
});

  // 上传人脸库
  export const uploadFaceLib = (data?: any) =>
  request({
    url: "/seeplusfacediscern/faceapi/faceLib",
    method: "post",
    
    data: {
      isFormRequest: true,
      ...data
    }
  });

    // 相似度对比
export const faceLibCompare = (data?: any) =>
request({
  url: "/seeplusfacediscern/faceapi/faceLib/compare",
  method: "post",
  data: {
    isFormRequest: true,
    ...data
  }
});