import React, { Component } from "react";
import "./index.less";
import Swiper from "swiper";

class Solution extends Component {
  private slides: any;
  componentDidMount() {
    new Swiper(".swiper-container", {
      watchSlidesProgress: true,
      slidesPerView: "auto",
      centeredSlides: true,
      loop: true,
      loopedSlides: 5,
      autoplay: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        //clickable :true,
      },
      on: {
        progress: function (swiper:any, progress: any) {
          const slides = swiper.slides;
          for (let i = 0; i < slides.length; i++) {
            var slide = slides.eq(i);
            var slideProgress = slides[i].progress;
            let modify = 1;
            if (Math.abs(slideProgress) > 1) {
              modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
            }
            let translate = slideProgress * modify * 260 + "px";
            let scale = 1 - Math.abs(slideProgress) / 5;
            let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
            slide.transform(
              "translateX(" + translate + ") scale(" + scale + ")"
            );
            slide.css("zIndex", zIndex);
            slide.css("opacity", 1);
            if (Math.abs(slideProgress) > 3) {
              slide.css("opacity", 0);
            }
          }
        },
        setTransition: function (swiper:any, transition: any) {
          const slides = swiper.slides;
          for (var i = 0; i < slides.length; i++) {
            var slide = slides.eq(i);
            slide.transition(transition);
          }
        },
      },
    });
  }
  render() {
    return (
      <div className="solution">
        <div className="nav-bg"></div>

        <section className="sv-tianyan">
          <p>
            <span>
              ”天衍“ <br />
              自动标签算法系统
            </span>
          </p>
          <p>
            基于到访即偏好，通过每一个数据节点采集消费者逛店行为，形成用户标签大数据系统
          </p>
        </section>

        <section className="sv-app">
          <p className="title">
            <span>应用场景</span>
          </p>
      
          <div id="certify">
            <div className="swiper-container">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <img alt="" src={require("@/assets/sv-swiper1.png")} />
                  <p>非常难得又值钱的认证证书</p>
                </div>
                <div className="swiper-slide">
                  <img alt="" src={require("@/assets/sv-swiper2.png")} />
                  <p>深圳市优秀互联网企业认定证书</p>
                </div>
                <div className="swiper-slide">
                  <img alt="" src={require("@/assets/sv-swiper3.png")} />
                  <p>质量管理体系认证荣誉证书</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className = "sv-consumer">
          <div>
            <div>
            <img alt="" src={require("@/assets/sv-bt-package.png")} />
            </div>
            <p>消费者多次出入星巴克咖啡 系统自动生成标签为咖啡爱 好者、商务人士等</p>
          </div>
          <div>
            <div>
            <img alt="" src={require("@/assets/sv-bt-package.png")} />
            </div>
            <p>消费者多次出入星巴克咖啡 系统自动生成标签为咖啡爱 好者、商务人士等</p>
          </div>
          <div>
            <div>
            <img alt="" src={require("@/assets/sv-bt-package.png")} />
            </div>
            <p>消费者多次出入星巴克咖啡 系统自动生成标签为咖啡爱 好者、商务人士等</p>
          </div>
        </section>
      </div>
    );
  }
}

export default Solution;
