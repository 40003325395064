import React, { Component } from 'react'
import FaceImage from '@/components/FaceImage/index'
import Spin from '@/components/Spin/index'
import Button from '@/components/Button/index'
import { uploadFaceLib, faceLibCompare } from '@/request/api'
import '../index.less'

class AlgorithmicExperience extends Component<any> {
  state = {
    imgFile: null,
    activeIndex: -1,
    imgUrl: '',
    imgArray: Array.from(Array(13)),
    actualImgLength: 0,
    componentName: '',
    similarity: 0,
    uploadFileLoading: false,
    retrievalLoading: false
  }
  inputFile: any = null

  private async onFileChange(e: any) {
    const files = e.target.files

    if (files.length > 20) {
      alert('所选图片不能超过20张')
      return
    }
    this.setState({
      uploadFileLoading: true
    })
    uploadFaceLib({
      type: 1,
      files
    })
      .then((resultArr: any) => {
        if (resultArr.find((val: number) => val !== 0)) {
          alert('多张人脸中存在人脸检测失败图片')
        }
        let imgLength = 0
        for (let i = 0; i < files.length; i++) {
          if (resultArr[i] === 0) {
            this.getImageBase64(files[i], imgLength)
            imgLength++
          }
        }
        this.setState({
          uploadFileLoading: false,
          actualImgLength: imgLength,
          activeIndex: -1
        })
      })
      .catch(() => {
        this.setState({
          uploadFileLoading: false
        })
      })
  }

  private getImageBase64(file: File, index: number) {
    const { imgArray } = this.state
    if (file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e: any) => {
        const src = e.target.result
        imgArray[index] = src
        this.setState({
          imgArray
        })
      }
    }
  }

  private async retrievalImg() {
    const { imgFile, actualImgLength } = this.state

    if (!imgFile || !actualImgLength) {
      alert('请先上传两侧图片')
      return
    }

    const timer = setInterval(() => {
      if (this.state.activeIndex < actualImgLength - 1) {
        this.setState({
          activeIndex: this.state.activeIndex + 1,
          retrievalLoading: true
        })
      } else {
        this.setState({
          activeIndex: 0
        })
      }
    }, 100)

    setTimeout(async () => {
      await faceLibCompare({ file: imgFile })
        .then((result: any) => {
          const maxValue = Math.max(...result)
          const maxIndex = result.findIndex((val: number) => val === maxValue)
          clearInterval(timer)
          this.setState({
            activeIndex: maxIndex,
            similarity: maxValue,
            retrievalLoading: false
          })
        })
        .catch(() => {
          clearInterval(timer)
          this.setState({
            activeIndex: -1,
            similarity: 0,
            retrievalLoading: false
          })
        })
    }, 1000)
  }

  render() {
    const {
      imgUrl,
      activeIndex,
      imgArray,
      similarity,
      uploadFileLoading,
      retrievalLoading
    } = this.state
    return (
      <div className={`experience-container ${this.props.className}`}>
        <div className="experience-container-info">
          <div className="left-retrieval-container">
            <div className="picture-wall">
              <Spin loading={uploadFileLoading}>
                <div className="picture-container">
                  {imgArray.map((src, index) => {
                    return (
                      <div
                        className={`pic-item ${
                          activeIndex === index ? 'active' : null
                        }`}
                      >
                        <div className="img-info">
                          {src && <img src={src} alt="" />}
                        </div>
                      </div>
                    )
                  })}

                  <label className="pic-item" htmlFor="upload">
                    <div className="img-info cross"></div>
                  </label>
                  <input
                    type="file"
                    className="upload-multiple"
                    onChange={(e) => this.onFileChange(e)}
                    name="upload"
                    multiple
                    id="upload"
                  />
                </div>
              </Spin>
            </div>
          </div>
          <div className="right-retrieval-container">
            <div className="result-img-container">
              <FaceImage
                handFile={(e: any) => (this.inputFile = e)}
                getFile={(file: File) =>
                  this.setState({
                    imgFile: file
                  })
                }
                imgUrl={imgUrl}
                hiddenBtn={true}
              />
              <div className="upload-img experience-result">
                <Button
                  className="location-img-uplaod"
                  onClick={() => this.retrievalImg()}
                  loading={retrievalLoading}
                  text="检索图片"
                />

                <div className="request-info">相似度：{ (similarity * 100).toFixed(2)  + '%' || 0}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AlgorithmicExperience
