import React, { Component } from 'react'
import { Link } from 'react-router-dom'
// Import Swiper React components

// Import Swiper styles
import 'swiper/swiper.less'
import './index.less'
class Home extends Component {
  state = {
    showFirstIndex: 0,
    showCurrentIndex: 0,
    productInfo: [
      {
        text1: '硬件产品',
        leftText1: <Link to = "/product/zhike" >AIOT管道硬件</Link>,
        leftText2: '覆盖线下消费全场景',
        rightText1: 'AI客群分析摄像机',
        rightText11:
          '基于深度学习和视觉技术构建的客群分析终端，包含检测、追踪、采集、分析模块。',
        // rightText2: '智能AI营销终端',
        // rightText22:
        //   '端侧计算，智能营销终端，赋能商业广告屏，实现精准广告分发和商品推荐。',
        rightText3: '巡店AI摄像机',
        rightText33: '端+云的视频理解能力，实现门店巡检+督导的全自动化。',
        link: ''
      },
      {
        text1: '算法产品',
        leftText1: <Link to = "/product/vipface">天衍算法</Link>,
        leftText2: '赋能终端硬件开启“智慧之眼”',
        rightText1: '口罩识别',
        rightText11: '在佩戴口罩的情况下，实现精准的对比识别，属性分析。',
        rightText2: '特征属性',
        rightText22: '自动识别性别、年龄、表情、颜值等属性。',
        rightText3: '服装',
        rightText33: '自动识别服装颜色款式、背包、帽子、眼镜等多维特征。',
        link: ''
      },
      {
        text1: '数据产品',
        leftText1: <Link to = "/analysis-platform">视加SaaS</Link>,
        leftText2: '实用价值模型助力经营提升',
        rightText1: '门店AI数据雷达',
        rightText11:
          '数十种客户行为分析数据模型，为商业经营决策者提供全局、实时的门店AI数据雷达。',
        rightText2: 'AI导购雷达',
        rightText22:
          '智能客户识别营销工具，帮助导购挖掘潜在客户，精准商品推荐，实现个性化导购服务。',
        link: ''
      },
      {
        text1: '开放平台',
        leftText1: 'API接口',
        leftText2: '兼容、开放，全场景软硬件对接',
        rightText1: '第三方平台接入',
        rightText11: '支持传统商业CRM、BI系统、ERP商管系统对接。',
        rightText2: '算法开放平台',
        rightText22:
          '聚焦于商业场景算法识别任务，兼容第三方人脸摄像机、人脸识别平板等智能硬件接入',
        link: ''
      }
    ],
    solveInfo: [
      {
        text1: '●重构传统客流系统，',
        text11: '建立以“顾客”为中心的经营方式',
        text2:
          '基于视觉算法、数据挖掘技术，构建新一代商业空间客户行为分析系统，采集更多维度的客户数据，聚焦单体顾客消费行为洞察。'
      },
      {
        text1: '●赋能传统广告屏，',
        text11: '精准广告分发和商品推荐',
        text2:
          '基于数字ID 建立消费者行为数据库，品牌广告可在特定时间和空间位置的广告分发窗口进行可视化展示，从而引导“潜在”顾客进店。'
      },
      {
        text1: '●取代人工巡视门店，',
        text11: '提升管理效率降低经营成本。',
        text2:
          '基于人工智能的视频理解技术，提高人工巡店效率；AI实时监控门店环境、商品陈列、员工状态等，并形成巡店报告，实现门店运营AI监管。'
      }
    ]
  }

  componentDidMount() {}

  render() {
    const {
      showCurrentIndex,
      showFirstIndex,
      productInfo,
      solveInfo
    } = this.state
    return (
      <div className="home">
        <div className="nav-bg">
          <video
            className="alert-video-video"
            autoPlay
            muted
            loop
            src={require('./video.mp4')}
          />
          <div className="nav-title animate__animated animate__backInUp">
            <p> 汇聚数据价值&nbsp;构建商业智能引擎</p>
          </div>
        </div>

        <section className="home-ai">
          <div className="home-section-title">
            <div className="title">
              <p>产品矩阵</p>
              <p>Products online</p>
            </div>
            {/* <div className="desc">slogan：用户线下行为全链条分析</div> */}
          </div>

          <ul className="ul-desc">
            {[1, 2, 3, 4].map((_, index) => {
              return (
                <li
                  onMouseEnter={(e) =>
                    this.setState({
                      showFirstIndex: index
                    })
                  }
                  className={`li-block ${
                    showFirstIndex === index ? 'li-block-active' : ''
                  }`}
                >
                  <p className="li-title">{productInfo[index].text1}</p>
                  <div className="li-left">
                    <div className="ll-text-1">
                      {productInfo[index].leftText1}
                    </div>
                    <img
                      className="ll-img"
                      src={
                        showFirstIndex === index
                          ? require(`@/assets/matrix-img-active-${
                              index + 1
                            }.png`)
                          : require(`@/assets/matrix-img-${index + 1}.png`)
                      }
                      alt=""
                    />

                    <div className="ll-text-2">
                      {' '}
                      {productInfo[index].leftText2}
                    </div>
                    {/* <div
                      className={`li-btn ${
                        showFirstIndex === index ? '' : 'hidden'
                      }`}
                    >
                      <Link to={productInfo[index].link}>了解更多</Link>
                    </div> */}
                  </div>
                  <div
                    className={`li-right ${
                      showFirstIndex === index ? '' : 'hidden'
                    }`}
                  >
                    <div className="lr-title">产品简介：</div>
                    <div className="lr-list">
                      <p>{productInfo[index].rightText1}</p>
                      <p>{productInfo[index].rightText11}</p>
                    </div>
                    <div className="lr-list">
                      <p>{productInfo[index].rightText2}</p>
                      <p>{productInfo[index].rightText22}</p>
                    </div>
                    {productInfo[index].rightText3 && (
                      <div className="lr-list">
                        <p>{productInfo[index].rightText3}</p>
                        <p>{productInfo[index].rightText33}</p>
                      </div>
                    )}
                  </div>
                </li>
              )
            })}
          </ul>
        </section>

        <section className="home-product">
          <div className="home-section-title">
            <div className="title">
              <p>解决方案</p>
              <p>Industry solutions</p>
            </div>
            {/* <div className="desc">
              提升业务效率、扩大品牌效应、匹配规模体量、从先进的产品开始
            </div> */}
          </div>
          <div className="bg" />
          <div className="home-product-swiper">
            <div className="swiper-img swiper-img1">
              <div className="swiper-img1-text1">
                {/* <p>"视加" 客户行为分析数据平台</p> */}
              </div>
              <div className="swiper-img1-text2">
                <p>更智能的AI洞察，不一样的商业发现</p>
                {/* <p>视加客户分析平台</p>
                <p>
                  构建消费者全流程行为数据。包括性别年龄属性、购买偏好、消费能力评估
                </p>
                <p>
                  同环对比、RFM、转化漏斗等数十种数据模型，全方位综合分析消费者行为
                </p> */}
              </div>
              <div className="swiper-img1-card1">
                <img src={require('@/assets/home-pc-card.png')} alt="" />
                <img src={require('@/assets/home-radar-1.png')} alt="" />
              </div>
              <div className="swiper-img1-card2">
                <img src={require('@/assets/swiper-img1-card2.png')} alt="" />
              </div>
            </div>
          </div>

          <ul className="pdt-list">
            {[1, 2, 3].map((_, index) => {
              return (
                <li
                  className={`pdt-item ${
                    showCurrentIndex === index ? 'pdt-item-active' : ''
                  }`}
                  onMouseEnter={(e) =>
                    this.setState({
                      showCurrentIndex: index
                    })
                  }
                  key={index}
                >
                  <div className={`watermark han-symbol num${index + 1} da`} />
                  <div className="ch-title">
                    <p>{solveInfo[index].text1}</p>
                    <p>{solveInfo[index].text11}</p>
                  </div>
                  {/* <div className="en-title">
                    <span>Machine Intelligence</span>
                  </div> */}
                  <div
                    className={`main-cont ${
                      showCurrentIndex === index ? '' : 'hidden'
                    }`}
                  >
                    <div className="wrap">
                      {/* <ul>
                        <li>
                          <div>
                            <span className="han-symbol item-symbol item-symbol-1"></span>
                            <span className="item-symbol-text">
                              智能实验室{' '}
                            </span>
                          </div>
                        </li>
                        <li>
                          <div>
                            <span className="han-symbol item-symbol item-symbol-2"></span>
                            <span className="item-symbol-text">
                              {' '}
                              智能实验室{' '}
                            </span>
                          </div>
                        </li>
                        <li>
                          <div>
                            <span className="han-symbol item-symbol item-symbol-3"></span>
                            <span className="item-symbol-text">
                              {' '}
                              智能实验室{' '}
                            </span>
                          </div>
                        </li>
                        <li>
                          <div>
                            <span className="han-symbol item-symbol item-symbol-4"></span>
                            <span className="item-symbol-text">
                              {' '}
                              智能实验室{' '}
                            </span>
                          </div>
                        </li>
                        <li>
                          <div>
                            <span className="han-symbol item-symbol item-symbol-5"></span>
                            <span className="item-symbol-text">
                              {' '}
                              智能实验室{' '}
                            </span>
                          </div>
                        </li>
                      </ul> */}
                      <div className="brief">{solveInfo[index].text2}</div>
                    </div>
                  </div>
                </li>
              )
            })}
          </ul>
        </section>
        <section className="home-partner">
          <div className="home-section-title">
            <div className="title">
              <p>合作伙伴</p>
              <p>Cooperative partner</p>
            </div>
            {/* <div className="desc">
              提升业务效率、扩大品牌效应、匹配规模体量、从先进的产品开始
            </div> */}
          </div>
          <div className="wallpaper-box">
            <img alt="" src={require('@/assets/product-wallpaper.png')} />
            <div className="wallpaper-more">
              {/* <Link to="/partner" className="link">
                查看更多
              </Link> */}
            </div>

            {/* <div className="wallpaper-box-wrap"></div> */}
          </div>
        </section>
      </div>
    )
  }
}

export default Home
