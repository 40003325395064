import React, { Component } from "react";
import "./index.less";

interface IProps {
  loading?: boolean;
  text?: string;
  className?: string; // 类名
  onClick?: () => void;
}

interface IState {
  propLoading: boolean;
  loading: boolean;
}

class Spin extends Component<IProps> {
  state = {
    loading: false
  };

  render() {
    const { className, loading } = this.props;
    return (
      <div
        className={`${className}  spin`}
      >
        <div className={`${loading ? "spin-spininng" : "spin-hidden"} `}>
          <span className="spin-dot">
            <i className="spin-dot-item"></i>
            <i className="spin-dot-item"></i>
            <i className="spin-dot-item"></i>
            <i className="spin-dot-item"></i>
          </span>
          <div className="spin-text">加载中...</div>
        </div>
        <div className="spin-container">{this.props.children}</div>
      </div>
    );
  }
}

export default Spin;
