import React, { Component } from 'react'
import './index.less'

class SolutionMall extends Component {
  render() {
    return (
      <div className="solution-brand">
        <div className="nav-bg">
          <section className="sm-challenge">
            <p>粗放式经营向精细化、数字化的运营模式迭代</p>
            <p>提升品牌连锁的转化率、客单价、复购率</p>
          </section>
        </div>

        <section className="sb-app">
          <div className="section-title">
            <div className="title">
              <p>解决方案及价值</p>
              <p>Solution & Value</p>
            </div>
            <div className="desc">
            线下多节点采集终端，关联海量历史数据，建立全行为链分析系统，助力门店精细化运营
            </div>
          </div>
          <div className="sb-cl-ti"></div>
          <div className="sb-cv-img">
            <img src={require('@/assets/sb-app.png')} alt="" />
          </div>
        </section>
        <section className="sm-tx-img ">
          <div className="se-lt-tx">
            <div className="left">
              <p className="title">数据化驱动运营    提升门店管理水平</p>
              <p className="content">
              基于多维度数据挖掘（销售额、客单价、成交率等），精准客流归因及未来客流预测，提前预判和定位问题。结合ERP数据、POS交易数据、CRM系统，健全KPI考核体系，帮助管理者精细化管理，实时掌控整体运行状态。
              </p>
            </div>
            <div className="right">
              <img
                className="sb-img-1"
                src={require('@/assets/sb-img-1.png')}
                alt=""
              />
            </div>
          </div>
        </section>
        <section className="sm-tx-img sm-data">
          <div className="se-lt-img ">
            <div className="left">
              <img src={require('@/assets/pi-vipface-service.png')} alt="" />
            </div>
            <div className="right">
              <p className="title">智能AI营销工具 提高销售转化效率  </p>
              <p className="content">每一笔交易绑定数字ID，无感泛会员注册，高效沉淀私域流量；基于历史消费行为，结合数据挖掘模型，形成个体行为画像；系统自动识别“潜在成交”、“高价值”客户，【AI导购雷达】实时到店提醒；“猜你喜欢”，精准推荐商品。</p>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

export default SolutionMall
