import React, { Component } from 'react'

import FaceImage from '@/components/FaceImage/index'
import '../index.less'

class AlgorithmicExperience extends Component<any> {
  state = {
    imgUrl: '',
    componentName: '',
    selectIndex: -1
  }
  inputFile: any = null

  private async onFileChange() {
    this.setState(
      {
        imgUrl: ''
      },
      () => {
        this.inputFile.current!.click()
      }
    )
  }

  private getImageSrc(img: string) {
    this.setState({
      imgUrl: img
    })
  }
  render() {
    const { imgUrl, selectIndex } = this.state
    return (
      <div className={`experience-container ${this.props.className}`}>
        <div className="experience-container-info">
          <div className="result-img-container">
            <FaceImage
              handFile={(e: any) => (this.inputFile = e)}
              imgUrl={imgUrl}
              hiddenBtn={true}
            />
          </div>
          <div className="right-container">
            <div className="experience-img-wall">
              <div className="picture-wall">
                <div className="picture-container">
                  {Array.from(Array(10)).map((_, index) => {
                    return (
                      <div className="pic-item" key = {`pic-item-${index}`}>
                        <img
                          className={`img-info ${
                            selectIndex === index && 'active'
                          } `}
                          onClick={(e) => {
                            this.getImageSrc(
                              require(`@/assets/face/face-${index + 1}.jpg`)
                            )
                            this.setState({
                              selectIndex: index
                            })
                          }}
                          src={require(`@/assets/face/face-${index + 1}.jpg`)}
                          alt=""
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="upload-img">
              <div
                className="location-img-uplaod"
                onClick={() => this.onFileChange()}
              >
                上传本地照片
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AlgorithmicExperience
