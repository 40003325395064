import React, { Component } from 'react'
import './index.less'

class ZhiKe extends Component {
  state = {
    activeIndex: -1,
    isStopActive: false,
    textArray: [
      'AI芯片',
      '深度学习',
      '流量监测',
      '顾客画像',
      '身份识别',
      '数据预测',
      '断网续传',
      '传输加密'
    ]
  }

  private timer: any = null

  componentDidUpdate() {
    const { activeIndex, isStopActive } = this.state

    const throttle = (fn: Function, wait: number) => {
      let timer: any = null
      return (...args: any) => {
        if (!timer) {
          timer = setTimeout(() => {
            fn.apply(this, args)
            timer = null
          }, wait)
        }
      }
    }
    if (!isStopActive && 8 > activeIndex) {
      const setActive = () =>
        this.setState({
          activeIndex: this.state.activeIndex + 1
        })
      throttle(setActive, 500)()
    } else {
      this.timer && clearTimeout(this.timer)
    }
  }

  componentDidMount() {
    // (document.getElementById('root') as any).contentEditable = true
    this.setState({
      activeIndex: this.state.activeIndex + 1
    })
  }

  render() {
    const { activeIndex, textArray } = this.state
    return (
      <div className="zk">
        <div className="nav-bg">
          <div className="tp-txt">
            <p>AIOT管道硬件</p>
            <p>围绕商业要素“人”的数字化，实现全场景管道硬件覆盖</p>
            <p>内含完整的感知、识别和分析模块，内嵌行业领先的深度学习算法</p>
          </div>
          <div className="bm-btn">
            {textArray.map((text, index) => (
              <div
                key={text}
                onMouseOver={() => {
                  this.setState({
                    isStopActive: true,
                    activeIndex: index
                  })
                }}
              >
                <button
                  className={`btn one ${activeIndex === index && 'active'}`}
                >
                  {text}
                </button>
              </div>
            ))}
          </div>
        </div>

        <section className="zk-se-li">
          <div className="title">
            <span>•“知客”智能AI客群摄像机</span>
          </div>

          <div className="zk-container">
            <div className="left">
              <img
                className="zk-li-img-1"
                src={require('@/assets/sxt.png')}
                alt=""
              />
            </div>
            <div className="right">
              <div className="integral">
                <div className="container">
                  <div className="ct-b-li">
                    <div className="ct-b-ct">
                      <div>
                        <img
                          alt=""
                          src={require('@/assets/ai-flow-img-1.png')}
                        />
                        <p>自动变焦</p>
                      </div>
                    </div>
                    <div className="ct-b-ct">
                      <div>
                        <img
                          alt=""
                          src={require('@/assets/ai-flow-img-2.png')}
                        />
                        <p>120dB宽动态</p>
                      </div>
                    </div>
                  </div>
                  <div className="ct-b-li">
                    <div className="ct-b-ct">
                      <div>
                        <img
                          alt=""
                          src={require('@/assets/ai-flow-img-3.png')}
                        />
                        <p>4K画质</p>
                      </div>
                    </div>
                    <div className="ct-b-ct">
                      <div>
                        <img
                          alt=""
                          src={require('@/assets/ai-flow-img-4.png')}
                        />
                        <p>POE供电 </p>
                      </div>
                    </div>
                  </div>
                  <div className="ct-b-li">
                    <div className="ct-b-ct">
                      <div>
                        <img
                          alt=""
                          src={require('@/assets/ai-flow-img-5.png')}
                        />
                        <p>30FPS帧率</p>
                      </div>
                    </div>
                    <div className="ct-b-ct">
                      <div>
                        <img
                          alt=""
                          src={require('@/assets/ai-flow-img-6.png')}
                        />
                        <p>人脸跟踪 </p>
                      </div>
                    </div>
                  </div>
                  <div className="ct-b-li">
                    <div className="ct-b-ct">
                      <div>
                        <img
                          alt=""
                          src={require('@/assets/ai-flow-img-7.png')}
                        />
                        <p>自然流线设计</p>
                      </div>
                    </div>
                    <div className="ct-b-ct">
                      <div>
                        <img
                          alt=""
                          src={require('@/assets/ai-flow-img-8.png')}
                        />
                        <p>7天断网续传 </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="zk-se-li">
          <div className="title">
            <span>• VIP Face支付平板</span>
          </div>
          <div className="zk-container">
            <div className="left">
              <div className="integral">
                <div className="container">
                  <div className="ct-b-li">
                    <div className="ct-b-ct">
                      <div>
                        <img
                          alt=""
                          src={require('@/assets/vip-face-img-1.png')}
                        />
                        <p>3D结构光</p>
                      </div>
                    </div>
                    <div className="ct-b-ct">
                      <div>
                        <img
                          alt=""
                          src={require('@/assets/vip-face-img-2.png')}
                        />
                        <p>11.6寸大屏</p>
                      </div>
                    </div>
                  </div>
                  <div className="ct-b-li">
                    <div className="ct-b-ct">
                      <div>
                        <img
                          alt=""
                          src={require('@/assets/vip-face-img-3.png')}
                        />
                        <p>1080P分辨率</p>
                      </div>
                    </div>
                    <div className="ct-b-ct">
                      <div>
                        <img
                          alt=""
                          src={require('@/assets/vip-face-img-4.png')}
                        />
                        <p>矩阵麦克风</p>
                      </div>
                    </div>
                  </div>
                  <div className="ct-b-li">
                    <div className="ct-b-ct">
                      <div>
                        <img
                          alt=""
                          src={require('@/assets/vip-face-img-5.png')}
                        />
                        <p>40W扬声器</p>
                      </div>
                    </div>
                    <div className="ct-b-ct">
                      <div>
                        <img
                          alt=""
                          src={require('@/assets/vip-face-img-6.png')}
                        />
                        <p>输入键盘</p>
                      </div>
                    </div>
                    <div className="ct-b-li">
                      <div className="ct-b-ct">
                        <div>
                          <img
                            alt=""
                            src={require('@/assets/vip-face-img-7.png')}
                          />
                          <p>人脸识别支付</p>
                        </div>
                      </div>
                      <div className="ct-b-ct">
                        <div>
                          <img
                            alt=""
                            src={require('@/assets/vip-face-img-8.png')}
                          />
                          <p>互动营销</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right">
              <img
                className="zk-li-img-2"
                src={require('@/assets/vip.png')}
                alt=""
              />
            </div>
          </div>
        </section>

        {/* <section className="zk-bt">
          <div>
            <p>多环境融合 抓拍画质高清</p>
            <p>
              宽动态范围达120db，抓拍更多细节
              <br /> 确保逆光等复杂环境下人脸抓拍清晰
            </p>
            <p>
              <span>星光级CMOS</span>
              <span>防水防尘</span>
              <span>3D降噪技术</span>
            </p>
          </div>
        </section> */}
      </div>
    )
  }
}

export default ZhiKe
