export const setCookie = (name: string, value: string) => {
  var Days = 30;
  var exp = new Date();
  exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
  document.cookie =
    name + "=" + escape(value) + ";expires=" + exp.toUTCString();
};

export const getCookie = (name: string) => {
  var arr,
    reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  arr = document.cookie.match(reg);
  if (arr) {
    return unescape(arr[2]);
  }
  return null;
};

/**
 * @param variable 为字符串时候获取单个参数值，为true 获取全部参数
 * 获取url参数
 */
 export const getQueryVariable = (variable?:string) => {
  var query = window.location.href.split("?")[1];
  if (query) {
    var vars = query.split("&");
    const allParamsObj:any = {};
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) {
        return pair[1];
      } else {
        allParamsObj[pair[0]] = pair[1];
      }
    }
    if (!variable) {
      return allParamsObj as any;
    }
  }
  return false;
};